<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{ $t('seller.breadcrumb.seller') }}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'marketingtools' }">{{ $t('seller.breadcrumb.marketPlatform') }}
				</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'combomealList' }">{{ $t('seller.breadcrumb.combomeal') }}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="combomeal-steps">
				<el-steps :active="stepActive" align-center>
					<el-step v-for="item in $t('seller.combomeal.stepsOption')" :key="item" :title="item"></el-step>
				</el-steps>
			</div>
			<template v-if="stepActive === 0">
				<!-- 选择商品 -->
				<div class="combomeal-main">
					<div class="combomeal-goods">
						<div class="goods-main">
							<div class="goods-title">{{ $t('seller.combomeal.mainGoodsPic') }}</div>
							<div class="goods-list">
								<div class="item" v-if="mainGoods.goods_id > 0">
									<div class="pic"><img :src="mainGoods.default_image" @error="goodsLoadError" alt=""></div>
									<div class="item-main">
										<div class="name">{{ mainGoods.goods_name }}</div>
										<div class="price">￥{{ mainGoods.price }}</div>
									</div>
									<div class="item-bottom">
										<el-button type="text" icon="el-icon-edit-outline"
											@click="handleGoodsShow(1)">{{ $t('seller.actions.modify') }}</el-button>
									</div>
								</div>
								<div v-else class="item item-upload" @click="handleGoodsShow(1)">
									<i class="el-icon-plus"></i>
									<p>{{ $t('seller.actions.addCombomealGoods') }}</p>
								</div>
							</div>
						</div>
						<div class="goods-sub">
							<div class="goods-title">{{ $t('seller.combomeal.subGoods') }}（{{ subGoods.length }}/8）
							</div>
							<div class="goods-list">
								<div class="item" v-for="(item, index) in subGoods" :key="index">
									<div class="pic"><img :src="item.default_image" @error="goodsLoadError" alt=""></div>
									<div class="item-main">
										<div class="name">{{ item.goods_name }}</div>
										<div class="price">￥{{ item.price }}</div>
									</div>
									<div class="item-bottom">
										<el-button type="text" icon="el-icon-delete"
											@click="handleGoodsDelete(index)"></el-button>
										<el-button type="text" icon="el-icon-arrow-left"
											@click="handleGoodsLeft(index)" :disabled="index === 0"></el-button>
										<el-button type="text" icon="el-icon-arrow-right"
											@click="handleGoodsRight(index)"
											:disabled="subGoods.length - 1 === index"></el-button>
									</div>
								</div>
								<div v-if="subGoods.length < 8" class="item item-upload"
									@click="handleGoodsShow(2)">
									<i class="el-icon-plus"></i>
									<p>{{ $t('seller.actions.addCombomealGoods') }}</p>
								</div>
							</div>
						</div>
						<el-dialog :title="dialogGoodsType | typeFilter($t('seller.combomeal.dialogType'))"
							:visible.sync="dialogVisibleGoods" width="900px" custom-class="seller-dialog">
							<div class="dialog-goods" v-if="dialogVisibleGoods">
								<el-form size="mini" inline>
									<el-form-item>
										<el-select v-model="goodsQuery.store_cate_id">
											<el-option :label="$t('seller.combomeal.allCate')"
												:value="0"></el-option>
											<el-option v-for="(item, index) in cateOptions" :key="index"
												:label="item.cate_name" :value="item.cate_id"></el-option>
											<el-option :label="$t('seller.combomeal.other')"
												:value="-1"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item>
										<el-input v-model="goodsQuery.goods_name"
											:placeholder="$t('seller.placeholder.babyName')"></el-input>
									</el-form-item>
									<el-form-item>
										<el-input v-model="goodsQuery.goods_code"
											:placeholder="$t('seller.placeholder.babyCode')"></el-input>
									</el-form-item>
									<el-form-item>
										<el-input v-model="goodsQuery.goods_id"
											:placeholder="$t('seller.placeholder.babyId')"></el-input>
									</el-form-item>
									<el-form-item>
										<el-button type="primary"
											@click="handleSearchDialog">{{ $t('seller.actions.search') }}</el-button>
									</el-form-item>
								</el-form>
								<el-table v-loading="listLoading" ref="dialogTable" :data="goodsList"
									:row-key="getDialogRowKey" @selection-change="handleSelectionChangeGoods">
									<el-table-column v-if="dialogGoodsType === 1" width="50">
										<template slot-scope="scope">
											<el-radio v-model="checkGoodsMain" :disabled="scope.row.if_show !== 1" :label="JSON.stringify(scope.row)">{{ }}</el-radio>
										</template>
									</el-table-column>
									<el-table-column v-if="dialogGoodsType === 2" type="selection" width="50"
										:selectable="(row, index) => { return row.goods_id !== mainGoods.goods_id ? true : false }"
										:reserve-selection="true">
									</el-table-column>
									<el-table-column :label="$t('seller.tableHead.babyDescribe')">
										<template slot-scope="scope">
											<div class="goods-item">
												<a :href="$itemBasePath + '/item/' + scope.row.goods_id"
													class="item-pic" target="_blank">
													<img :src="scope.row.default_image" @error="goodsLoadError" alt="">
												</a>
												<div class="item-main">
													<a class="name"
														:href="$itemBasePath + '/item/' + scope.row.goods_id"
														target="_blank">{{ scope.row.goods_name }}</a>
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column width="100px" align="center">
										<template slot-scope="scope">
											<el-tag v-if="scope.row.if_show !== 1" type="warning" size="mini"><i class="el-icon-warning-outline"></i>不可选择</el-tag>
										</template>
									</el-table-column>
									<el-table-column :label="$t('seller.tableHead.unitPrice')">
										<template slot-scope="scope">￥{{ scope.row.price }}</template>
									</el-table-column>
									<el-table-column :label="$t('seller.tableHead.stock')"
										prop="stock"></el-table-column>
									<el-table-column :label="$t('seller.tableHead.totalSales')"
										prop="sales_num"></el-table-column>
								</el-table>
								<pagination v-show="goodsTotal > 0" :total="goodsTotal" :page.sync="goodsCurr"
									:limit.sync="goodsSize" @pagination="getGoodsList"></pagination>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-button @click="dialogVisibleGoods = false" size="mini">{{ $t('seller.actions.cancel') }}</el-button>
								<el-button type="primary" @click="handleConfirmGoods" size="mini">{{ $t('seller.actions.confirm') }}</el-button>
							</div>
						</el-dialog>
					</div>
				</div>
				<div class="combomeal-bottom">
					<el-button type="primary" size="mini" @click="handleNextStep(1)">{{ $t('seller.combomeal.nextPackage') }}</el-button>
					<el-button size="mini" @click="handleCancel">{{ $t('seller.actions.cancel') }}</el-button>
				</div>
			</template>
			<template v-else-if="stepActive === 1">
				<!-- 设置套餐 -->
				<div class="combomeal-main">
					<div class="combomeal-set-meal">
						<div class="set-meal-preview">
							<div class="preview-wrap">
								<el-scrollbar class="preview-main">
									<swiper v-if="combomealForm.img_urls && combomealForm.img_urls.length > 0"
										ref="mySwiper" :options="swiperOptions">
										<swiper-slide v-for="(img, index) in combomealForm.img_urls" :key="index">
											<img class="swiper-pic" :src="img" @error="goodsLoadError" alt=""
												width="100%">
										</swiper-slide>
										<div class="swiper-pagination" slot="pagination"></div>
									</swiper>
									<div v-else class="swiper-pic"><img :src="$imageErrorGoods" alt=""></div>
									<div class="preview-info">
										<div class="title">{{ combomealForm.name }}</div>
										<div class="desc">{{ combomealForm.desc }}</div>
										<div class="line-price">
											<span class="price-label">{{ $t('seller.combomeal.price') }}</span>
											<span class="price"><em>￥</em>{{ onPriceRange }}</span>
										</div>
									</div>
									<div class="preview-goods">
										<div class="title">{{ $t('seller.combomeal.subGoods') }}</div>
										<div class="list-goods">
											<el-checkbox-group v-model="checkPreviewGoods">
												<div class="item">
													<el-checkbox :label="mainGoods.goods_id" disabled>{{ }}</el-checkbox>
													<div class="item-main">
														<div class="item-pic"><img :src="mainGoods.default_image"
																alt="" @error="goodsLoadError"></div>
														<div class="item-box">
															<div class="number">x1</div>
															<div class="name">{{ mainGoods.goods_name }}</div>
															<div class="price">￥{{ mainGoods.price }}</div>
															<div class="next"
																v-if="mainGoods.goods_specs && mainGoods.goods_specs.length > 0">
																<i class="el-icon-arrow-right"></i>
															</div>
														</div>
													</div>
												</div>
												<div class="item" v-for="item in subGoods" :key="item.goods_id">
													<el-checkbox :label="item.goods_id"
														:disabled="combomealForm.type === 2">{{ }}</el-checkbox>
													<div class="item-main">
														<div class="item-pic"><img :src="item.default_image" @error="goodsLoadError" alt="">
														</div>
														<div class="item-box">
															<div class="number">x1</div>
															<div class="name">{{ item.goods_name }}</div>
															<div class="price">￥{{ item.price }}</div>
															<div class="next"
																v-if="item.goods_specs && item.goods_specs.length > 0">
																<i class="el-icon-arrow-right"></i>
															</div>
														</div>
													</div>
												</div>
											</el-checkbox-group>
										</div>
									</div>
									<div class="preview-block"></div>
									<div class="preview-bottom">
										<div class="price-box">
											<span class="text">{{ $t('seller.combomeal.price') }}</span>
											<span class="price"><em>￥</em>{{ onCollocationPrice }}</span>
										</div>
										<el-button type="danger" round size="mini">{{ $t('seller.actions.buyNow') }}</el-button>
									</div>
								</el-scrollbar>
							</div>
							<div class="preview-tips">{{ $t('seller.combomeal.previewTips') }}</div>
						</div>
						<div class="set-meal-wrap">
							<div class="set-meal-hd">{{ $t('seller.tableHead.campaignInfo') }}</div>
							<div class="set-meal-bd">
								<el-form ref="combomealForm1" :model="combomealForm" :rules="combomealRule"
									label-width="100px" size="mini">
									<el-form-item :label="$t('seller.tableHead.packageName') + '：'" prop="name">
										<el-input v-model="combomealForm.name" maxlength="10" show-word-limit
											style="width: 200px;"></el-input>
									</el-form-item>
									<el-form-item :label="$t('seller.tableHead.packageDesc') + '：'" prop="desc">
										<el-input v-model="combomealForm.desc" type="textarea" rows="3"
											maxlength="50" show-word-limit style="width: 400px;"></el-input>
									</el-form-item>
									<el-form-item :label="$t('seller.tableHead.packageType') + '：'" prop="type">
										<el-radio-group v-model="combomealForm.type"
											@change="handleChangeCombomealType">
											<el-radio v-for="item in $t('seller.options.packageType')"
												:key="item.value" :label="item.value">
												{{ item.label }}
												<el-popover placement="top" trigger="hover" :content="item.tips"><i slot="reference" class="el-icon-question"></i></el-popover>
											</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item :label="$t('seller.tableHead.packageAttr') + '：'" prop="attr">
										<el-radio-group v-model="combomealForm.attr">
											<el-radio v-for="item in $t('seller.options.packageAttr')"
												:key="item.value" :label="item.value">{{ item.label }}</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item label-width="0">
										<div>{{ $t('seller.combomeal.packagePic') }}（{{ combomealForm.img_urls.length }}/5）{{ $t('seller.combomeal.packagePicTips') }}</div>
										<div class="upload-images">
											<div class="img-item" v-for="(img, index) in combomealForm.img_urls"
												:key="index">
												<div class="item-pic"><img :src="img" alt=""></div>
												<div class="item-bottom">
													<el-button type="text" icon="el-icon-delete"
														@click="handleThumbDelete(index)"></el-button>
													<el-button type="text" icon="el-icon-arrow-left"
														@click="handleThumbLeft(index)"
														:disabled="index === 0"></el-button>
													<el-button type="text" icon="el-icon-arrow-right"
														@click="handleThumbRight(index)"
														:disabled="combomealForm.img_urls.length - 1 === index"></el-button>
												</div>
											</div>
											<div v-if="combomealForm.img_urls.length < 5"
												class="img-item upload-text" @click="handleThumbShow">
												<div class="item-pic">
													<i class="el-icon-plus"></i>
													<p>{{ $t('seller.upload.image') }}</p>
												</div>
											</div>
										</div>
										<div>{{ $t('seller.combomeal.packageTips') }}</div>
									</el-form-item>
								</el-form>
							</div>
						</div>
					</div>
					<el-dialog :title="$t('seller.dialogTitle.image')" width="960px" :modal="false"
						:visible.sync="dialogVisibleThumb" :before-close="handleThumbClose" custom-class="seller-dialog">
						<upload-material ref="uploadImage" :key="uploadRandom" @get-uplod-action="handleThumbUpload"
							:upload-type="true" :upload-action="'cropping'" :cropper-scale="[1,1]"
							:min-width="750">
						</upload-material>
					</el-dialog>
				</div>
				<div class="combomeal-bottom">
					<el-button type="primary" size="mini" @click="handleNextStep(2)">{{ $t('seller.combomeal.nextGoods') }}</el-button>
					<el-button size="mini" @click="handlePrevStep(0)">{{ $t('seller.actions.prevStep') }}</el-button>
				</div>
			</template>
			<template v-else-if="stepActive === 2">
				<!-- 设置优惠 -->
				<div class="combomeal-main">
					<div class="combomeal-discount">
						<el-form ref="combomealForm2" :model="combomealForm" :rules="combomealRule"
							label-width="100px" size="mini">
							<el-form-item :label="$t('seller.formItem.basicCoupon')"
								label-width="68px"></el-form-item>
							<div class="item-section">
								<el-form-item :label="$t('seller.unit.freeShipping') + '：'">
									<el-checkbox v-model="combomealForm.shipping_free"
										:label="$t('seller.combomeal.freight')" :true-label="1"
										:false-label="0"></el-checkbox>
								</el-form-item>
							</div>
							<el-form-item :label="$t('seller.formItem.moreCoupon')"
								label-width="68px"></el-form-item>
							<div class="item-section">
								<el-form-item :label="$t('seller.formItem.selectCoupon') + '：'" prop="dis_sel">
									<el-radio-group v-model="combomealForm.dis_sel"
										@change="handleChangeDiscountSel">
										<el-radio v-for="item in $t('seller.options.selectCoupon')"
											:key="item.value" :label="item.value">{{ item.label }}</el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item v-if="combomealForm.dis_sel === 1"
									:label="$t('seller.formItem.couponType') + '：'" prop="dis_type">
									<el-radio v-model="combomealForm.dis_type" :label="1">{{ $t('seller.combomeal.skuDiscount') }}</el-radio>
								</el-form-item>
							</div>
							<el-form-item :label="$t('seller.formItem.activitySet')"
								label-width="68px"></el-form-item>
							<div class="item-section">
								<template v-if="combomealForm.dis_sel === 1">
									<div class="section-title">{{ $t('seller.combomeal.couponInfo') }}</div>
									<el-form-item :label="$t('seller.campaign.activityTime') + '：'" prop="end_time">
										<el-date-picker v-model="discountTime" type="datetimerange"
											:range-separator="$t('seller.unit.to')"
											:start-placeholder="$t('seller.placeholder.startDate')"
											:end-placeholder="$t('seller.placeholder.endDate')"
											value-format="timestamp" :picker-options="pickerOptions"
											@change="changeDatePicker">
										</el-date-picker>
										<span v-if="discountTime" style="margin-left: 10px">{{ $t('seller.combomeal.duration') }}{{ onRangeTime }}</span>
										<span
											v-if="combomealForm.end_time && combomealForm.end_time * 1000 < Date.now()"
											style="margin-left: 10px;color: #f40;">{{ $t('seller.combomeal.overdue') }}</span>
									</el-form-item>
									<el-form-item>
										<el-checkbox v-model="combomealForm.is_preheat"
											:label="$t('seller.combomeal.preheat')" :true-label="1"
											:false-label="0"></el-checkbox>
										<span style="margin-left: 20px">{{ $t('seller.combomeal.advance') }}</span>
										<el-select v-model="combomealForm.preheat_value"
											style="width: 100px;margin: 0 5px;">
											<el-option v-for="item in 11" :key="item" :label="item"
												:value="item"></el-option>
										</el-select>
										<span>{{ $t('seller.unit.day') }}</span>
									</el-form-item>
								</template>
								<el-form-item label-width="0">
									<table class="discount-table" width="100%">
										<thead>
											<tr>
												<th>{{ $t('seller.tableHead.goodsName') }}</th>
												<th>{{ $t('seller.tableHead.goodsAttr') }}</th>
												<th>{{ $t('seller.tableHead.costPrice') }}</th>
												<th style="width: 200px;">{{ $t('seller.combomeal.number') }}</th>
												<th v-if="combomealForm.dis_sel === 1">{{ $t('seller.combomeal.price') }}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<div class="goods-item">
														<a :href="$itemBasePath + '/item/' + mainGoods.goods_id"
															class="item-pic" target="_blank">
															<img :src="mainGoods.default_image" @error="goodsLoadError" alt="">
														</a>
														<div class="item-main">
															<a class="name"
																:href="$itemBasePath + '/item/' + mainGoods.goods_id"
																target="_blank">{{ mainGoods.goods_name }}</a>
															<div class="desc">{{ $t('seller.screen.goodsId') + '：' }}{{ mainGoods.goods_id }}</div>
														</div>
													</div>
												</td>
												<td align="center">{{ $t('seller.combomeal.mainGoods') }}</td>
												<td align="center">￥{{ onGoodsPrice(mainGoods) }}</td>
												<td align="center" v-if="discountGoods[mainGoods.goods_id]">
													<template v-if="discountGoods[mainGoods.goods_id].is_num === 1">
														<el-input-number v-model="numberOptions[mainGoods.goods_id]"
															controls-position="right" :min="1" :max="mainGoods.stock>1000?1000:mainGoods.stock"
															style="width: 100px;margin-right: 10px;"></el-input-number>
														<el-button type="text" size="medium"
															@click="handleCancelNumber(mainGoods.goods_id)">{{ $t('seller.actions.cancel') }}</el-button>
														<el-button type="text" size="medium"
															@click="handleConfirmNumber(mainGoods.goods_id)">{{ $t('seller.actions.confirm') }}</el-button>
													</template>
													<template v-else>
														<span style="margin-right: 5px;">{{ discountGoods[mainGoods.goods_id].num }}</span>
														<el-button type="text" size="medium"
															icon="el-icon-edit-outline"
															@click="handleVisibleNumber(mainGoods.goods_id)"></el-button>
													</template>
												</td>
												<td v-else align="center">
													<span>1</span>
													<el-button type="text" size="medium" icon="el-icon-edit-outline"
														@click="handleVisibleNumber(mainGoods.goods_id)"></el-button>
												</td>
												<td v-if="combomealForm.dis_sel === 1" align="center">
													<span style="margin-right: 5px;">￥{{ discountRangePrice(mainGoods.goods_id) }}</span>
													<el-button type="text" size="medium" icon="el-icon-edit-outline"
														@click="handleVisibleDiscount(mainGoods.goods_id)"></el-button>
												</td>
											</tr>
											<tr v-for="item in subGoods" :key="item.goods_id">
												<td>
													<div class="goods-item">
														<a :href="$itemBasePath + '/item/' + item.goods_id"
															class="item-pic" target="_blank">
															<img :src="item.default_image" @error="goodsLoadError" alt="">
														</a>
														<div class="item-main">
															<a class="name"
																:href="$itemBasePath + '/item/' + item.goods_id"
																target="_blank">{{ item.goods_name }}</a>
															<div class="desc">{{ $t('seller.screen.goodsId') + '：' }}{{ item.goods_id }}</div>
														</div>
													</div>
												</td>
												<td align="center">{{ $t('seller.combomeal.subGoods') }}</td>
												<td align="center">￥{{ onGoodsPrice(item) }}</td>
												<td align="center">
													<template v-if="discountGoods[item.goods_id].is_num === 1">
														<el-input-number v-model="numberOptions[item.goods_id]"
															controls-position="right" :min="1" :max="item.stock > 1000 ? 1000:item.stock"
															style="width: 100px;margin-right: 10px;"></el-input-number>
														<el-button type="text" size="medium"
															@click="handleCancelNumber(item.goods_id)">{{ $t('seller.actions.cancel') }}</el-button>
														<el-button type="text" size="medium"
															@click="handleConfirmNumber(item.goods_id)">{{ $t('seller.actions.confirm') }}</el-button>
													</template>
													<template v-else>
														<span style="margin-right: 5px;">{{ discountGoods[item.goods_id].num }}</span>
														<el-button type="text" size="medium"
															icon="el-icon-edit-outline"
															@click="handleVisibleNumber(item.goods_id)"></el-button>
													</template>
												</td>
												<td v-if="combomealForm.dis_sel === 1" align="center">
													<span style="margin-right: 5px;">￥{{ discountRangePrice(item.goods_id) }}</span>
													<el-button type="text" size="medium" icon="el-icon-edit-outline"
														@click="handleVisibleDiscount(item.goods_id)"></el-button>
												</td>
											</tr>
											<tr>
												<td></td>
												<td></td>
												<td>{{ $t('seller.tableHead.costPrice') }}￥{{ totalPrice }}</td>
												<td></td>
												<td v-if="combomealForm.dis_sel === 1">{{ $t('seller.tableHead.packagePrice') }}￥{{ totalPackagePrice }}
												</td>
											</tr>
										</tbody>
									</table>
								</el-form-item>
							</div>
						</el-form>
						<el-dialog :title="$t('seller.combomeal.setSkuPrice')" width="900px"
							:visible.sync="dialogVisibleDiscount" custom-class="seller-dialog">
							<div class="dialog-discount" v-if="discountForm">
								<ul class="discount-filter">
									<li>{{ $t('seller.combomeal.batchSet') }}</li>
									<li>
										<el-select v-model="discountQuery.type" size="mini">
											<el-option v-for="item in $t('seller.options.discountType')"
												:key="item.label" :label="item.label"
												:value="item.value"></el-option>
										</el-select>
									</li>
									<li>
										<el-input v-model="discountQuery.value" size="mini"
											style="width: 100px;"></el-input>
									</li>
								</ul>
								<table class="discount-table" width="100%">
									<thead>
										<tr>
											<th></th>
											<th align="left">{{ $t('seller.tableHead.stock') }}</th>
											<th align="left">{{ $t('seller.tableHead.goodsPrice') }}</th>
											<th align="left">{{ $t('seller.tableHead.shopCombomealPrice') }}</th>
										</tr>
									</thead>
									<tbody v-if="discountForm.spec_list && discountForm.spec_list.length > 0">
										<tr v-for="(item, index) in discountForm.spec_list" :key="index">
											<td>{{ $t('seller.formItem.mainColor') }}: {{ item.spec_1 }},{{ $t('seller.formItem.goodsSize') }}: {{ item.spec_2 }}</td>
											<td>{{ item.stock }}</td>
											<td>￥{{ item.origin_price | priceFilter() }}</td>
											<td><el-input v-model="item.dis_price" size="mini"
													oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
													style="width: 100px;"></el-input></td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr>
											<td>{{ $t('seller.tag.default') }}</td>
											<td>{{ discountForm.stock }}</td>
											<td>￥{{ discountForm.origin_price | priceFilter() }}</td>
											<td><el-input v-model="discountForm.dis_price" size="mini"
													oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)"
													style="width: 100px;"></el-input></td>
										</tr>
									</tbody>
								</table>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-button @click="dialogVisibleDiscount = false" size="mini">{{ $t('seller.actions.cancel') }}</el-button>
								<el-button type="primary" @click="handleConfirmDiscount" size="mini">{{ $t('seller.actions.confirm') }}</el-button>
							</div>
						</el-dialog>
					</div>
				</div>
				<div class="combomeal-bottom">
					<el-button type="primary" size="mini" @click="handleSave">{{ $t('seller.actions.savePackage') }}</el-button>
					<el-button size="mini" @click="handlePrevStep(1)">{{ $t('seller.actions.prevStep') }}</el-button>
				</div>
			</template>
			<template v-else-if="stepActive === 3">
				<!-- 设置套餐 -->
				<div class="combomeal-main">
					<div class="combomeal-set-meal">
						<div class="set-meal-preview">
							<div class="preview-wrap">
								<el-scrollbar class="preview-main">
									<swiper v-if="combomealForm.img_urls && combomealForm.img_urls.length > 0"
										ref="mySwiper" :options="swiperOptions">
										<swiper-slide v-for="(img, index) in combomealForm.img_urls" :key="index">
											<img class="swiper-pic" :src="img" @error="goodsLoadError" alt=""
												width="100%">
										</swiper-slide>
										<div class="swiper-pagination" slot="pagination"></div>
									</swiper>
									<div v-else class="swiper-pic"><img :src="$imageErrorGoods" alt=""></div>
									<div class="preview-info">
										<div class="title">{{ combomealForm.name }}</div>
										<div class="desc">{{ combomealForm.desc }}</div>
										<div class="line-price">
											<span class="price-label">{{ $t('seller.combomeal.price') }}</span>
											<span class="price"><em>￥</em>{{ onPriceRange }}</span>
										</div>
									</div>
									<div class="preview-goods">
										<div class="title">{{ $t('seller.combomeal.subGoods') }}</div>
										<div class="list-goods">
											<el-checkbox-group v-model="checkPreviewGoods">
												<div class="item">
													<el-checkbox :label="mainGoods.goods_id" disabled>{{ }}</el-checkbox>
													<div class="item-main">
														<div class="item-pic"><img :src="mainGoods.default_image"
																alt="" @error="goodsLoadError"></div>
														<div class="item-box">
															<div class="number">x1</div>
															<div class="name">{{ mainGoods.goods_name }}</div>
															<div class="price">￥{{ mainGoods.price }}</div>
															<div class="next"
																v-if="mainGoods.goods_specs && mainGoods.goods_specs.length > 0">
																<i class="el-icon-arrow-right"></i>
															</div>
														</div>
													</div>
												</div>
												<div class="item" v-for="item in subGoods" :key="item.goods_id">
													<el-checkbox :label="item.goods_id">{{ }}</el-checkbox>
													<div class="item-main">
														<div class="item-pic"><img :src="item.default_image" @error="goodsLoadError" alt="">
														</div>
														<div class="item-box">
															<div class="number">x1</div>
															<div class="name">{{ item.goods_name }}</div>
															<div class="price">￥{{ item.price }}</div>
															<div class="next"
																v-if="item.goods_specs && item.goods_specs.length > 0">
																<i class="el-icon-arrow-right"></i>
															</div>
														</div>
													</div>
												</div>
											</el-checkbox-group>
										</div>
									</div>
									<div class="preview-block"></div>
									<div class="preview-bottom">
										<div class="price-box">
											<span class="text">{{ $t('seller.combomeal.price') }}</span>
											<span class="price"><em>￥</em>{{ onCollocationPrice }}</span>
										</div>
										<el-button type="danger" round size="mini">{{ $t('seller.actions.buyNow') }}</el-button>
									</div>
								</el-scrollbar>
							</div>
							<div class="preview-tips">{{ $t('seller.combomeal.previewTips') }}!</div>
						</div>
						<div class="set-meal-wrap">
							<div class="set-meal-hd">{{ $t('seller.tableHead.campaignInfo') }}</div>
							<div class="set-meal-bd">
								<el-alert :title="$t('seller.combomeal.successTips')" type="success" show-icon
									:closable="false"></el-alert>
								<vue-qr
									:text="$itemBasePath + '/m/collocation/' + combomealInfo.collocation_treasure_id"></vue-qr>
							</div>
						</div>
					</div>
				</div>
				<div class="combomeal-bottom">
					<router-link :to="{ name: 'combomealList' }"><el-button type="primary" size="mini">{{ $t('seller.actions.backCombomealHome') }}</el-button></router-link>
				</div>
			</template>
		</el-card>
	</el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import Pagination from '@/components/seller/pagination'
import { datePicker } from "@/utils/index"
import VueQr from 'vue-qr'
import uploadMaterial from '@/components/seller/uploadMaterial'
import { fetchTreasuresCategory, fetchCombomealInfo, fetchCombomealGoods, createCombomeal, updateCombomeal } from '@/api/seller/market'

const defaultDiscount = {
	type: 1,
	value: ''
}

const defaultQuery = {
	store_cate_id: 0,
	goods_name: '',
	goods_code: '',
	goods_id: '',
	if_show: ''
}

export default {
	components: {
		VueQr,
		Pagination,
		uploadMaterial
	},
	computed: {
		...mapGetters([
			'userInfo'
		]),
		onPriceRange() {
			var text = ''
			if (this.mainGoods.goods_id > 0 && this.subGoods.length > 0) {
				var sum = this.mainGoods.price * 1
				this.subGoods.forEach(item => {
					sum += item.price * 1
				})
				if (this.combomealForm.type === 1) {
					text = this.mainGoods.price + '-' + sum.toFixed(2)
				} else {
					text = sum.toFixed(2)
				}
			}
			return text
		},
		onCollocationPrice() {
			var sum = this.mainGoods.price * 1
			if (this.combomealForm.type === 2) {
				this.subGoods.forEach(item => {
					sum += item.price * 1
				})
			}
			return sum.toFixed(2)
		},
		onRangeTime() {
			let text = ''
			if (this.combomealForm.start_time > 0 && this.combomealForm.end_time > 0) {
				var longTime = parseInt(this.combomealForm.end_time - this.combomealForm.start_time)
				var dd = parseInt(longTime / 86400)
				var longH = longTime % 86400
				var hh = parseInt(longH / 3600)
				var longM = longH % 3600
				var mm = parseInt(longM / 60)
				var ss = longM % 60
				if (dd > 0) {
					text += dd + '天'
				}
				if (hh > 0) {
					text += hh + '时'
				}
				if (mm > 0) {
					text += mm + '分'
				}
				if (ss > 0) {
					text += ss + '秒'
				}
			}
			return text
		},
		totalPrice() {
			var minTotal = 0
			var maxTotal = 0
			if (this.mainGoods.goods_specs && this.mainGoods.goods_specs.length > 0) {
				var min = 0
				var max = 0
				this.mainGoods.goods_specs.forEach(child => {
					if (!min > 0 || min > parseFloat(child.price)) {
						min = parseFloat(child.price)
					}
					if (!max > 0 || max < parseFloat(child.price)) {
						max = parseFloat(child.price)
					}
				})
				minTotal += min
				maxTotal += max
			} else {
				minTotal += parseFloat(this.mainGoods.price)
				maxTotal += parseFloat(this.mainGoods.price)
			}
			this.subGoods.forEach(item => {
				if (item.goods_specs && item.goods_specs.length > 0) {
					var min_s = 0
					var max_s = 0
					item.goods_specs.forEach(child => {
						if (!min_s > 0 || min_s > parseFloat(child.price)) {
							min_s = parseFloat(child.price)
						}
						if (!max_s > 0 || max_s < parseFloat(child.price)) {
							max_s = parseFloat(child.price)
						}
					})
					minTotal += min_s
					maxTotal += max_s

				} else {
					minTotal += parseFloat(item.price)
					maxTotal += parseFloat(item.price)
				}

			})
			if (minTotal !== maxTotal) {
				return minTotal.toFixed(2) + '~' + maxTotal.toFixed(2)
			} else {
				return minTotal.toFixed(2)
			}
		},
		totalPackagePrice() {
			var minTotal = 0
			var maxTotal = 0
			if (this.discountGoods) {
				for (const key in this.discountGoods) {
					const goods = this.discountGoods[key]
					if (goods.spec_list && goods.spec_list.length > 0) {
						var min = 0
						var max = 0
						goods.spec_list.forEach(spec => {
							var spec_price = spec.dis_price || spec.origin_price
							if (!min > 0 || parseFloat(spec_price) < min) {
								min = parseFloat(spec_price)
							}
							if (!max > 0 || parseFloat(spec_price) > max) {
								max = parseFloat(spec_price)
							}
						})
						minTotal += min
						maxTotal += max
					} else {
						var goods_price = goods.dis_price || goods.origin_price
						minTotal += parseFloat(goods_price)
						maxTotal += parseFloat(goods_price)
					}
				}
			}
			if (minTotal === maxTotal) {
				return minTotal.toFixed(2)
			} else {
				return minTotal.toFixed(2) + '~' + maxTotal.toFixed(2)
			}
		}
	},
	data() {
		return {
			stepActive: this.$route.query.step * 1 || 0,
			combomealInfo: {},
			cateOptions: [],
			goodsQuery: Object.assign({}, defaultQuery),
			listLoading: false,
			goodsList: [],
			goodsCurr: 1,
			goodsSize: 5,
			goodsTotal: 0,
			mainGoods: {},
			subGoods: [],
			dialogGoodsType: 1,
			dialogVisibleGoods: false,
			checkGoodsMain: '',
			swiperOptions: {
				loop: false,
				autoplay: true,
				pagination: {
					el: '.swiper-pagination',
				},
			},
			combomealForm: {
				name: '',
				desc: '',
				img_urls: [],
				type: 1,
				attr: 1,
				shipping_free: 0,
				dis_sel: 1,
				dis_type: 1,
				is_preheat: 0,
				preheat_value: '',
				start_time: '',
				end_time: ''
			},
			combomealRule: {
				name: [{ required: true, message: this.$t('seller.validator.packageName'), trigger: 'blur' }],
				desc: [{ required: true, message: this.$t('seller.validator.packageDesc'), trigger: 'blur' }],
				type: [{ required: true, message: this.$t('seller.validator.packageType'), trigger: 'change' }],
				attr: [{ required: true, message: this.$t('seller.validator.packageAttr'), trigger: 'change' }],
				dis_sel: [{ required: true, message: this.$t('seller.validator.packageCoupon'), trigger: 'change' }],
				dis_type: [{ required: true, message: this.$t('seller.validator.packageCouponType'), trigger: 'change' }],
				end_time: [{ required: true, message: this.$t('seller.validator.packageCouponTime'), trigger: 'change' }]
			},
			checkPreviewGoods: [],
			dialogVisibleThumb: false,
			uploadRandom: Math.random(),
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 86400
				},
			},
			discountTime: [Date.now(), datePicker(Date.now(), 0, 0, 30,'')],
			discountGoods: [],
			numberOptions: {},
			dialogVisibleDiscount: false,
			discountId: 0,
			discountQuery: Object.assign({}, defaultDiscount),
			discountForm: {}
		}
	},
	created() {
		this.getData()
	},
	methods: {
		goodsLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		async getData() {
			try {
				const category = await fetchTreasuresCategory()
				this.cateOptions = category.data.list
				if (this.$route.params.id > 0) {
					const { data } = await fetchCombomealInfo({ collocation_treasure_id: this.$route.params.id })
					this.combomealInfo = data

					const goods_list = sessionStorage.getItem(this.userInfo.userName + 'combomealItem' + this.$route.params.id)
					if (goods_list) {
						this.mainGoods = Object.assign({}, JSON.parse(goods_list).mainItems)
						this.subGoods = Object.assign([], JSON.parse(goods_list).subItems)
					} else {
						var sub_list = []
						data.list_dis_goods.forEach(item => {
							if (item.is_main !== 1) {
								sub_list.push(item.origin_goods)
							} else {
								this.mainGoods = Object.assign({}, item.origin_goods)
								this.checkGoodsMain = JSON.stringify(item.origin_goods)
							}
						})
						this.subGoods = sub_list
					}
					const combomeal_info = sessionStorage.getItem(this.userInfo.userName + 'combomealData' + this.$route.params.id)
					if (combomeal_info) {
						this.combomealForm = JSON.parse(combomeal_info)
					} else {
						this.combomealForm = {
							name: data.name,
							desc: data.desc,
							img_urls: data.img_urls.split(','),
							type: data.type,
							attr: data.attr,
							shipping_free: data.shipping_free,
							dis_sel: data.dis_sel,
							dis_type: data.dis_type,
							is_preheat: data.is_preheat,
							preheat_value: data.preheat_value > 0 ? data.preheat_value : '',
							start_time: data.start_time,
							end_time: data.end_time
						}
					}
					if (this.combomealForm.start_time > 0) {
						this.discountTime = [this.combomealForm.start_time * 1000, this.combomealForm.end_time * 1000]
					}
					if (data.list_dis_goods) {
						var discount_goods = {}
						var edit_goods = {}
						data.list_dis_goods.forEach(item => {
							if (item.goods_spec && item.goods_spec.length > 0) {
								item.goods_spec.forEach(child => {
									if (!child.dis_price * 1 > 0) {
										child.dis_price = ''
									}
								})
								discount_goods[item.goods_id] = { num: item.num, is_num: 0, is_main: item.is_main, spec_list: item.goods_spec }
							} else {
								discount_goods[item.goods_id] = { origin_price: item.origin_price, dis_price: item.dis_price || '', stock: item.stock, num: item.num, is_num: 0, is_main: item.is_main }
							}
							edit_goods[item.goods_id] = item.num
						})
						this.numberOptions = edit_goods
						this.discountGoods = discount_goods
					}
				} else {
					const goods_list = sessionStorage.getItem(this.userInfo.userName + 'combomealItem')
					if (goods_list) {
						this.mainGoods = Object.assign({}, JSON.parse(goods_list).mainItems)
						this.subGoods = Object.assign([], JSON.parse(goods_list).subItems)
					}
					const combomeal_info = sessionStorage.getItem(this.userInfo.userName + 'combomealData')
					if (combomeal_info) {
						this.combomealForm = JSON.parse(combomeal_info)
						if (this.combomealForm.start_time > 0) {
							this.discountTime = [this.combomealForm.start_time * 1000, this.combomealForm.end_time * 1000]
						}
					}
				}
				this.getDiscountGoods()
				var check_goods = [this.mainGoods.goods_id]
				if (this.combomealForm.type === 2) {
					this.subGoods.forEach(item => {
						check_goods.push(item.goods_id)
					})
				}
				this.checkPreviewGoods = check_goods
			} catch (error) {
				console.log(error)
			}
			this.getGoodsList()
		},
		getGoodsList() {
			this.listLoading = true
			const params = {
				...this.goodsQuery,
				page_index: (this.goodsCurr - 1) * this.goodsSize,
				page_size: this.goodsSize
			}
			fetchCombomealGoods(params).then(response => {
				const { data } = response
				this.goodsList = data.list
				this.goodsTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleCurrentChangeGoods(val) {
			this.goodsCurr = val
			this.getGoodsList()
		},
		handleGoodsShow(type) {
			this.goodsQuery = Object.assign({}, defaultQuery)
			if(type === 2) {
				this.goodsQuery.if_show = 1
			} else {
				this.goodsQuery.if_show = ''
			}
			if (type === 2 && !this.mainGoods.goods_id > 0) {
				this.$message.error(this.$t('seller.errorMsg.addMainGoods'))
				return false
			}
			this.dialogGoodsType = type
			this.goodsList = []
			this.goodsCurr = 1
			this.getGoodsList()
			this.dialogVisibleGoods = true
			this.$nextTick(() => {
				if (type === 2 && this.subGoods.length > 0) {
					this.subGoods.forEach(row => {
						this.$refs.dialogTable.toggleRowSelection(row)
					})
				}
			})
		},
		handleGoodsLeft(index) {
			if (index > 0) {
				this.subGoods[index] = this.subGoods.splice(index - 1, 1, this.subGoods[index])[0]
			}
		},
		handleGoodsRight(index) {
			if (index !== this.subGoods.length - 1) {
				this.subGoods[index] = this.subGoods.splice(index + 1, 1, this.subGoods[index])[0]
			}
		},
		handleGoodsDelete(index) {
			this.subGoods.splice(index, 1)
		},
		getDialogRowKey(row) {
			return row.goods_id
		},
		handleSearchDialog() {
			this.goodsCurr = 1
			this.getGoodsList()
		},
		handleSelectionChangeGoods(val) {
			if (val.length > 8) {
				for (let index = 8; index < val.length; index++) {
					this.$refs.dialogTable.toggleRowSelection(val[index], false)
				}
			}
		},
		handleConfirmGoods() {
			if (this.dialogGoodsType === 1) {
				const item = JSON.parse(this.checkGoodsMain)
				this.mainGoods = Object.assign({}, item)
			} else if (this.dialogGoodsType === 2) {
				this.subGoods = this.$refs.dialogTable.selection
			}
			this.dialogVisibleGoods = false
		},
		// 设置套餐
		handleChangeCombomealType(val) {
			var check_goods = [this.mainGoods.goods_id]
			if (this.combomealForm.type === 2) {
				this.subGoods.forEach(item => {
					check_goods.push(item.goods_id)
				})
			}
			this.checkPreviewGoods = check_goods
		},
		handleThumbUpload(data) {
			if(data.type === 1) {
				if (this.combomealForm.img_urls.length < 5) {
					this.combomealForm.img_urls.push(data.img_url)
					this.dialogVisibleThumb = false
				} else {
					this.$message.error(this.$t('seller.errorMsg.maxFile5'))
					return false
				}
			} else {
				this.dialogVisibleThumb = false
			}
		},
		handleThumbShow() {
			this.uploadRandom = Math.random()
			this.dialogVisibleThumb = true
		},
		handleThumbClose() {
			this.dialogVisibleThumb = false
		},
		handleThumbLeft(index) {
			if (index > 0) {
				this.combomealForm.img_urls[index] = this.combomealForm.img_urls.splice(index - 1, 1, this.combomealForm.img_urls[index])[0]
			}
		},
		handleThumbRight(index) {
			if (index !== this.combomealForm.img_urls.length - 1) {
				this.combomealForm.img_urls[index] = this.combomealForm.img_urls.splice(index + 1, 1, this.combomealForm.img_urls[index])[0]
			}
		},
		handleThumbDelete(index) {
			this.combomealForm.img_urls.splice(index, 1)
		},
		// 设置优惠
		onGoodsPrice(goods) {
			if (goods.goods_specs && goods.goods_specs.length > 0) {
				var min = 0
				var max = 0
				goods.goods_specs.forEach(child => {
					if (!min > 0 || min > parseFloat(child.price)) {
						min = parseFloat(child.price)
					}
					if (!max > 0 || max < parseFloat(child.price)) {
						max = parseFloat(child.price)
					}
				})
				if (min !== max) {
					return min.toFixed(2) + '~' + max.toFixed(2)
				} else {
					return min.toFixed(2)
				}
			} else {
				return goods.price
			}
		},
		handleChangeDiscountSel(val) {
			this.combomealForm.dis_type = 0
			if (val === 1) {
				if (this.discountTime) {
					this.combomealForm.start_time = parseInt(this.discountTime[0] / 1000)
					this.combomealForm.end_time = parseInt(this.discountTime[1] / 1000)
				}
			}
		},
		changeDatePicker(val) {
			if (val) {
				this.combomealForm.start_time = parseInt(val[0] / 1000)
				this.combomealForm.end_time = parseInt(val[1] / 1000)
			} else {
				this.combomealForm.start_time = ''
				this.combomealForm.end_time = ''
			}
		},
		getDiscountGoods() {
			var discount_goods = {}
			var edit_goods = {}
			if (this.discountGoods[this.mainGoods.goods_id]) {
				discount_goods[this.mainGoods.goods_id] = this.discountGoods[this.mainGoods.goods_id]
			} else if (this.mainGoods.goods_specs && this.mainGoods.goods_specs.length > 0) {
				var p_specs = []
				this.mainGoods.goods_specs.forEach(item => {
					p_specs.push({ spec_id: item.spec_id, spec_1: item.spec_1, spec_2: item.spec_2, origin_price: item.price * 1, dis_price: '', stock: item.stock })
				})
				discount_goods[this.mainGoods.goods_id] = { num: 1, is_num: 0, is_main: 1, spec_list: p_specs }
			} else {
				discount_goods[this.mainGoods.goods_id] = { origin_price: this.mainGoods.price * 1, dis_price: '', stock: this.mainGoods.stock, num: 1, is_num: 0, is_main: 1 }
			}
			edit_goods[this.mainGoods.goods_id] = discount_goods[this.mainGoods.goods_id].num
			this.subGoods.forEach(item => {
				if (this.discountGoods[item.goods_id]) {
					discount_goods[item.goods_id] = this.discountGoods[item.goods_id]
				} else if (item.goods_specs.length > 0) {
					var s_specs = []
					item.goods_specs.forEach(child => {
						s_specs.push({ spec_id: child.spec_id, spec_1: child.spec_1, spec_2: child.spec_2, origin_price: child.price * 1, dis_price: '', stock: child.stock })
					})
					discount_goods[item.goods_id] = { num: 1, is_num: 0, is_main: 0, spec_list: s_specs }
				} else {
					discount_goods[item.goods_id] = { origin_price: item.price * 1, dis_price: '', stock: item.stock, num: 1, is_num: 0, is_main: 0 }
				}
				edit_goods[item.goods_id] = discount_goods[item.goods_id].num
			})
			this.numberOptions = edit_goods
			this.discountGoods = discount_goods
		},
		discountRangePrice(id) {
			const goods = Object.assign({}, this.discountGoods[id])
			if (goods.spec_list && goods.spec_list.length > 0) {
				var min = 0
				var max = 0
				goods.spec_list.forEach(item => {
					var price = item.dis_price || item.origin_price
					if (!min > 0 || min > parseFloat(price)) {
						min = parseFloat(price)
					}
					if (!max > 0 || max < parseFloat(price)) {
						max = parseFloat(price)
					}
				})
				if (min !== max) {
					return min.toFixed(2) + '-' + max.toFixed(2)
				} else {
					return min.toFixed(2)
				}
			} else {
				var price = goods.dis_price || goods.origin_price
				return parseFloat(price).toFixed(2)
			}
		},
		handleVisibleNumber(id) {
			this.discountGoods[id].is_num = 1
			this.numberOptions[id] = parseInt(this.discountGoods[id].num)
		},
		handleCancelNumber(id) {
			this.discountGoods[id].is_num = 0
			this.numberOptions[id] = parseInt(this.discountGoods[id].num)
		},
		handleConfirmNumber(id) {
			this.discountGoods[id].is_num = 0
			if (this.numberOptions[id]) {
				this.discountGoods[id].num = parseInt(this.numberOptions[id])
			}
		},
		handleVisibleDiscount(id) {
			this.discountId = id
			this.discountForm = JSON.parse(JSON.stringify(this.discountGoods[id]))
			this.discountQuery = Object.assign({}, defaultDiscount)
			this.dialogVisibleDiscount = true
		},
		handleConfirmDiscount() {
			if (this.discountQuery.value) {
				if (this.discountQuery.type === 1) {
					if (this.discountForm.spec_list && this.discountForm.spec_list.length > 0) {
						var flag = false
						this.discountForm.spec_list.forEach(item => {
							var ratio_1 = this.discountQuery.value / item.origin_price * 10
							if (ratio_1 > 6.99 && ratio_1 < 10) {
								item.dis_price = this.discountQuery.value
							} else {
								flag = true
							}
						})
						if (flag) {
							this.$message.error(this.$t('seller.errorMsg.minCombomealPrice'))
							return false
						}
					} else {
						var ratio_2 = this.discountQuery.value / this.discountForm.origin_price * 10
						if (ratio_2 > 6.99 && ratio_2 < 10) {
							this.$set(this.discountForm, 'dis_price', this.discountQuery.value)
						} else {
							this.$message.error(this.$t('seller.errorMsg.minCombomealPrice'))
							return false
						}
					}

				} else if (this.discountQuery.type === 2) {
					if (this.discountQuery.value < 7 || this.discountQuery.value > 9.99) {
						this.$message.error(this.$t('seller.errorMsg.minCombomealPrice'))
						return false
					}
					if (this.discountForm.spec_list && this.discountForm.spec_list.length > 0) {
						this.discountForm.spec_list.forEach(item => {
							item.dis_price = item.origin_price * this.discountQuery.value / 10
						})
					} else {
						this.discountForm.dis_price = this.discountForm.origin_price * this.discountQuery.value / 10
					}
				}
			} else {
				if (this.discountForm.spec_list && this.discountForm.spec_list.length > 0) {
					var flag_2 = false
					this.discountForm.spec_list.forEach(item => {
						var ratio_3 = item.dis_price / item.origin_price * 10
						if (ratio_3 < 7 || ratio_3 > 9.99) {
							flag = true
						}
					})
					if (flag_2) {
						this.$message.error(this.$t('seller.errorMsg.minCombomealPrice'))
						return false
					}
				} else {
					var ratio_4 = this.discountForm.dis_price / this.discountForm.origin_price * 10
					if (ratio_4 < 7 || ratio_4 > 9.99) {
						this.$message.error(this.$t('seller.errorMsg.minCombomealPrice'))
						return false
					}
				}
			}
			this.discountGoods[this.discountId] = JSON.parse(JSON.stringify(this.discountForm))
			this.dialogVisibleDiscount = false
		},
		handleNextStep(step) {
			if (step === 1) {
				if (!this.mainGoods.goods_id > 0) {
					this.$message.error(this.$t('seller.errorMsg.selectMainGoods'))
					return false
				}
				if (this.subGoods.length < 1) {
					this.$message.error(this.$t('seller.errorMsg.minCombomealGoods'))
					return false
				}
				const data = {
					mainItems: this.mainGoods,
					subItems: this.subGoods
				}
				if (this.$route.params.id > 0) {
					sessionStorage.setItem(this.userInfo.userName + 'combomealItem' + this.$route.params.id, JSON.stringify(data))
				} else {
					sessionStorage.setItem(this.userInfo.userName + 'combomealItem', JSON.stringify(data))
				}

				var check_goods = [this.mainGoods.goods_id]
				if (this.combomealForm.type === 2) {
					this.subGoods.forEach(item => {
						check_goods.push(item.goods_id)
					})
				}
				this.checkPreviewGoods = check_goods

				this.stepActive = step
				this.$router.push({ query: { step: step }})
			} else if (step === 2) {
				this.$refs.combomealForm1.validate(valid => {
					if (valid) {
						if (!this.combomealForm.img_urls.length > 0) {
							this.$message.error(this.$t('seller.errorMsg.uploadPackagePic'))
							return false
						}
						this.getDiscountGoods()
						if (this.$route.params.id > 0) {
							sessionStorage.setItem(this.userInfo.userName + 'combomealData' + this.$route.params.id, JSON.stringify(this.combomealForm))
						} else {
							sessionStorage.setItem(this.userInfo.userName + 'combomealData', JSON.stringify(this.combomealForm))
						}
						this.stepActive = step
						this.$router.push({ query: { step: step }})
					} else {
						return false
					}
				})
			}
		},
		handlePrevStep(step) {
			if (step === 0) {
				if (this.$route.params.id > 0) {
					sessionStorage.setItem(this.userInfo.userName + 'combomealData' + this.$route.params.id, JSON.stringify(this.combomealForm))
				} else {
					sessionStorage.setItem(this.userInfo.userName + 'combomealData', JSON.stringify(this.combomealForm))
				}
			}
			this.stepActive = step
			this.$router.push({ query: { step: step }})
		},
		handleCancel() {
			if (this.$route.params.id > 0) {
				sessionStorage.removeItem(this.userInfo.userName + 'combomealItem' + this.$route.params.id)
				sessionStorage.removeItem(this.userInfo.userName + 'combomealData' + this.$route.params.id)
			} else {
				sessionStorage.removeItem(this.userInfo.userName + 'combomealItem')
				sessionStorage.removeItem(this.userInfo.userName + 'combomealData')
			}
			this.$router.push({ name: 'combomealList' })
		},
		handleSave() {
			if (this.combomealForm.dis_sel === 1 && this.discountTime) {
				if(parseInt(this.discountTime[1] / 1000) < parseInt(Date.now() / 1000)) {
					this.$message.error('活动时间已过期!')
					return false
				} else {
					this.combomealForm.start_time = parseInt(this.discountTime[0] / 1000)
					this.combomealForm.end_time = parseInt(this.discountTime[1] / 1000)
				}
			}
			this.$refs.combomealForm2.validate(async valid => {
				if (valid) {
					const params = {
						...this.combomealForm,
						join_goods: this.discountGoods
					}
					params.img_urls = params.img_urls.join(',')
					if (params.dis_sel !== 1) {
						for (const key in params.join_goods) {
							var goods = params.join_goods[key]
							if (goods.spec_list && goods.spec_list.length > 0) {
								goods.spec_list.forEach(item => {
									item.dis_price = ''
								})
							} else {
								goods.dis_price = ''
							}
							params.join_goods[key] = goods
						}
						params.start_time = ''
						params.end_time = ''
					}
					params.join_goods = JSON.stringify(params.join_goods)
					try {
						if (this.$route.params.id > 0) {
							params.collocation_treasure_id = this.$route.params.id
						}
						const { data } = this.$route.params.id ? await updateCombomeal(params) : await createCombomeal(params)
						if (this.$route.params.id > 0) {
							sessionStorage.removeItem(this.userInfo.userName + 'combomealItem' + this.$route.params.id)
							sessionStorage.removeItem(this.userInfo.userName + 'combomealData' + this.$route.params.id)
						} else {
							this.combomealInfo.collocation_treasure_id = data.collocation_treasure_id
							sessionStorage.removeItem(this.userInfo.userName + 'combomealItem')
							sessionStorage.removeItem(this.userInfo.userName + 'combomealData')
						}
						this.stepActive = 3
						this.$router.push({ params: { id: data.collocation_treasure_id }, query: { step: 3 }})
					} catch (error) {
						console.log(error)
					}
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>

.seller-card {

	.combomeal-steps {
		padding: 20px 0;
	}

	.combomeal-main {
		padding: 20px;
		min-height: 300px;
	}

	.combomeal-goods {
		display: flex;

		.goods-main {
			flex-shrink: 0;
			width: 100px;
			margin-right: 14px;

			.goods-list {
				.item {
					&.item-upload {
						color: #0065ff;
						border-color: #0065ff;
					}
				}
			}
		}

		.goods-sub {
			flex: 1;
			width: 0;

			.goods-list {
				border: 1px solid #e6e6e6;

				.item {
					margin-right: 14px;
					border-top: none;
					border-bottom: none;

					&:first-child {
						border-left: none;
					}

					&.item-upload {
						border: 1px dashed #a3a3a3;
					}
				}
			}
		}

		.goods-title {
			color: #666;
			font-size: 12px;
			line-height: 1.28571;
			margin: 8px 0;
		}

		.goods-list {
			display: flex;

			.item {
				flex-shrink: 0;
				width: 98px;
				height: 193px;
				border: 1px solid #e6e6e6;

				.pic {
					width: 100%;
					height: 100px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.item-main {
					padding: 8px 4px 9px;

					.name {
						height: 30px;
						color: #666666;
						font-size: 12px;
						line-height: 1.2;
						overflow: hidden;
						display: -webkit-box;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
					}

					.price {
						color: rgb(242, 63, 64);
						font-size: 14px;
						line-height: 1.28571;
						margin-top: 6px;
					}
				}

				.item-bottom {
					color: #0065ff;
					text-align: center;
					line-height: 22px;
					border-top: 1px solid #e6e6e6;

					[class^=el-icon-] {
						line-height: 22px;
						margin-right: 10px;
						cursor: pointer;

						&:last-child {
							margin-right: 0;
						}
					}
				}

				&.item-upload {
					height: 191px;
					color: #a3a3a3;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					cursor: pointer;
					border: 1px dashed #a3a3a3;


					[class^=el-icon-] {
						font-size: 32px;
					}

					p {
						margin-top: 12px;
						font-size: 12px;
					}
				}
			}
		}
	}

	.combomeal-set-meal {
		display: flex;

		.set-meal-preview {
			width: 320px;
			flex-shrink: 0;

			.preview-wrap {
				width: 320px;
				height: 590px;
				padding: 48px 2px;
				box-sizing: border-box;
				background-image: url(~@/assets/images/seller/mobile-760-1400.png);
				background-repeat: no-repeat;
				background-size: 100% auto;
				box-sizing: border-box;
			}

			.preview-main {
				height: 100%;
				background-color: #eee;

				.swiper-pic {
					width: 316px;
					height: 316px;
					object-fit: cover;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.swiper-container {
					position: relative;
					overflow: hidden;
				}

				::v-deep .swiper-pagination {
					height: 0;
					bottom: 20px;

					.swiper-pagination-bullet {
						width: 8px;
						height: 8px;
						background-color: #FFFFFF;
						opacity: .2;
					}

					.swiper-pagination-bullet-active {
						background-color: #f50;
						opacity: 1;
					}
				}

				.preview-info {
					padding: 15px 10px;
					margin-bottom: 8px;
					background-color: #FFFFFF;

					.title {
						font-size: 15px;
						line-height: 18px;
						margin-bottom: 8px;
					}

					.desc {
						color: #828282;
						font-size: 12px;
						line-height: inherit;
						margin-bottom: 8px;
						word-break: break-all;
					}

					.line-price {
						color: #f50;

						.price-label {
							font-size: 12px;
						}

						.price {
							em {
								font-size: 12px;
							}

							font-size: 14px;
						}
					}
				}

				.preview-goods {
					margin-bottom: 10px;
					background-color: #FFFFFF;

					.title {
						color: #051b28;
						height: 29px;
						line-height: 29px;
						padding: 0 10px;
						font-size: 14px;
					}

					.list-goods {
						.item {
							display: flex;
							align-items: center;

							::v-deep .el-checkbox {
								width: 36px;
								flex-shrink: 0;
								text-align: center;
							}

							.item-main {
								flex: 1;
								width: 0;
								padding: 10px 10px 10px 0;
								display: flex;
								border-bottom: 1px solid #eee;

								.item-pic {
									flex-shrink: 0;
									width: 74px;
									height: 74px;

									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}

								.item-box {
									width: 0;
									flex: 1;
									font-size: 12px;
									margin-left: 10px;

									.number {
										float: right;
										line-height: 16px;
									}

									.name {
										width: 160px;
										color: #333333;
										font-size: 12px;
										height: 32px;
										line-height: 16px;
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-box-orient: vertical;
										-webkit-line-clamp: 2;
										white-space: normal;
										word-break: break-all
									}

									.price {
										color: #f50;
										font-size: 12px;
										line-height: 1.2;
									}

									.next {
										text-align: right;
										margin-top: 10px;
									}
								}
							}

							&:last-child {
								.item-main {
									border-color: transparent;
								}
							}
						}
					}
				}

				.preview-block {
					height: 38px;
				}

				.preview-bottom {
					bottom: 0;
					width: 100%;
					padding: 0 10px;
					box-sizing: border-box;
					height: 38px;
					display: flex;
					z-index: 9;
					align-items: center;
					justify-content: flex-end;
					background-color: #FFFFFF;
					position: absolute;

					.price-box {
						margin-right: 10px;

						.text {
							font-size: 12px;
						}

						.price {
							color: #f40;
							font-size: 14px;

							em {
								font-size: 12px;
							}
						}
					}
				}
			}

			.preview-tips {
				margin-top: 15px;
				color: rgb(174, 174, 175);
				font-size: 12px;
				text-align: center;
				line-height: 1.28571;
			}
		}

		.set-meal-wrap {
			flex: 1;
			width: 0;
			margin-left: 32px;

			.set-meal-hd {
				font-size: 14px;
				line-height: 1.5;
				margin-bottom: 10px;
			}

			.set-meal-bd {
				.upload-images {
					margin: 16px 0 20px 5px;

					.img-item {
						width: 90px;
						display: inline-block;
						vertical-align: top;
						margin-right: 8px;
						border: 1px solid rgb(230, 230, 230);

						.item-pic {
							width: 90px;
							height: 90px;
							text-align: center;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						&.upload-text {
							border-color: #fafafa;
							cursor: pointer;

							.item-pic {
								color: #999999;
								display: flex;
								align-items: center;
								justify-content: center;
								flex-flow: column;
								background-color: #fafafa;

								[class^=el-icon-] {
									font-size: 32px;
								}
							}
						}

						.item-bottom {
							height: 30px;
							box-sizing: border-box;
							text-align: center;
							padding-top: 3px;

							[class^=el-icon-] {
								color: #ccc;
								cursor: pointer;
								font-size: 16px;
								line-height: 22px;
								margin-right: 10px;

								&.el-icon-delete {
									color: $--seller-primary;
								}

								&:last-child {
									margin-right: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.combomeal-discount {
		.item-section {
			padding: 22px 0 6px;
			margin-bottom: 10px;
			border: 1px solid #e6e6e6;

			.section-title {
				padding: 0 10px 22px;
				font-size: 14px;
				margin-bottom: 10px;
				border-bottom: 1px solid #e6e6e6;
			}
		}

		.discount-table {
			border-top: 1px solid #e6e7eb;

			th {
				color: #666666;
				font-weight: normal;
				background: #f7f8fa;
			}

			th,
			td {
				font-size: 12px;
				line-height: 20px;
				padding: 8px 16px;
				border-bottom: 1px solid #e6e7eb;
			}
		}

		.goods-item {
			display: flex;
			min-width: 200px;

			.item-pic {
				flex-shrink: 0;
				width: 80px;
				height: 80px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.item-main {
				flex: 1;
				width: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-left: 10px;

				.name {
					font-size: 12px;
					line-height: 16px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					white-space: normal;
					color: $--seller-primary;
				}
			}
		}
	}

	.combomeal-bottom {
		text-align: center;
		padding-bottom: 20px;
	}
}

.dialog-goods {
	.goods-item {
		display: flex;
		min-width: 200px;

		.item-pic {
			flex-shrink: 0;
			width: 60px;
			height: 60px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.item-main {
			flex: 1;
			width: 0;
			margin-left: 10px;

			.name {
				max-height: 60px;
				font-size: 14px;
				line-height: 30px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				white-space: normal;
				color: #666;
			}
		}
	}

	.dialog-bottom {
		padding-top: 10px;
		text-align: right;
	}
}

.dialog-discount {
	.discount-filter {
		margin-bottom: 10px;

		li {
			display: inline-block;
			margin-right: 10px;
		}
	}

	.discount-table {
		border: 1px solid #e6e7eb;

		th {
			background: #f7f8fa;
		}

		th,
		td {
			padding: 8px 16px;
		}
	}
}
</style>